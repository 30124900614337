import * as React from "react";

export type ColorType = "blue" | "green" | "red" | "yellow";
type ContextType = {
    color: ColorType;
    setColor: React.Dispatch<React.SetStateAction<ColorType>>;
};

const ColorContext = React.createContext({} as ContextType);

const useColorManager = () => {
    const [color, setColor] = React.useState<ColorType>("blue");
    return { color, setColor };
};

export const ColorProvider = ({ children }: { children: React.ReactNode }) => {
    return (
        <ColorContext.Provider value={useColorManager()}>
            {children}
        </ColorContext.Provider>
    );
};

type UseColorType = () => ContextType;
const useColor: UseColorType = () => {
    const context = React.useContext(ColorContext);
    if (!context) {
        throw new Error("useColor must be used within a ColorProvider");
    }
    return context;
};

export default useColor;
